import { render, staticRenderFns } from "./goods_type.vue?vue&type=template&id=3c599764&scoped=true"
import script from "./goods_type.vue?vue&type=script&lang=ts"
export * from "./goods_type.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c599764",
  null
  
)

export default component.exports