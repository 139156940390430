import request from '@/plugins/axios'

/** S 图片视频资源列表 **/
// 列表
export const apiSdvideoSourceLists = (params: any) => request.get('/settings.sdvideo/sdvideoSourceLists', {params})

// 添加
export const apiSdvideoSourceAdd = (params: any) => request.post('/settings.sdvideo/sdvideoSourceAdd', params)

// 详情
export const apiSdvideoSourceDetail = (params: any) => request.get('/settings.sdvideo/sdvideoSourceDetail', {params})

// 编辑
export const apiSdvideoSourceEdit = (params: any) => request.post('/settings.sdvideo/sdvideoSourceEdit', params)

// 删除
export const apiSdvideoSourceDel = (params: any) => request.post('/settings.sdvideo/sdvideoSourceDel', params)

/** S 服装类型列表 **/
// 列表
export const apiSdvideoGoodsTypeLists = (params: any) => request.get('/settings.sdvideo/sdvideoGoodsTypeLists', {params})

// 添加
export const apiSdvideoGoodsTypeAdd = (params: any) => request.post('/settings.sdvideo/sdvideoGoodsTypeAdd', params)

// 详情
export const apiSdvideoGoodsTypeDetail = (params: any) => request.get('/settings.sdvideo/sdvideoGoodsTypeDetail', {params})

// 编辑
export const apiSdvideoGoodsTypeEdit = (params: any) => request.post('/settings.sdvideo/sdvideoGoodsTypeEdit', params)

// 删除
export const apiSdvideoGoodsTypeDel = (params: any) => request.post('/settings.sdvideo/sdvideoGoodsTypeDel', params)

/** S 服装列表 **/
// 列表
export const apiSdvideoGoodsLists = (params: any) => request.get('/settings.sdvideo/sdvideoGoodsLists', {params})

// 添加
export const apiSdvideoGoodsAdd = (params: any) => request.post('/settings.sdvideo/sdvideoGoodsAdd', params)

// 详情
export const apiSdvideoGoodsDetail = (params: any) => request.get('/settings.sdvideo/sdvideoGoodsDetail', {params})

// 编辑
export const apiSdvideoGoodsEdit = (params: any) => request.post('/settings.sdvideo/sdvideoGoodsEdit', params)

// 删除
export const apiSdvideoGoodsDel = (params: any) => request.post('/settings.sdvideo/sdvideoGoodsDel', params)

/** S 模特姿势列表 **/
// 列表
export const apiSdvideoModelPoseLists = (params: any) => request.get('/settings.sdvideo/sdvideoModelPoseLists', {params})

// 添加
export const apiSdvideoModelPoseAdd = (params: any) => request.post('/settings.sdvideo/sdvideoModelPoseAdd', params)

// 详情
export const apiSdvideoModelPoseDetail = (params: any) => request.get('/settings.sdvideo/sdvideoModelPoseDetail', {params})

// 编辑
export const apiSdvideoModelPoseEdit = (params: any) => request.post('/settings.sdvideo/sdvideoModelPoseEdit', params)

// 删除
export const apiSdvideoModelPoseDel = (params: any) => request.post('/settings.sdvideo/sdvideoModelPoseDel', params)



