var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"ls-dialog__trigger",on:{"click":_vm.onTrigger}},[_vm._t("trigger")],2),_c('el-dialog',{attrs:{"coustom-class":"ls-dialog__content","title":_vm.title,"visible":_vm.visible,"width":_vm.width,"top":_vm.top,"modal-append-to-body":false,"append-to-body":true,"center":"","before-close":_vm.close,"close-on-click-modal":false},on:{"close":_vm.close}},[_c('div',{},[_c('el-form',{ref:"formRef",attrs:{"rules":_vm.formRules,"model":_vm.form,"label-width":"120px","size":"small"}},[_c('el-form-item',{attrs:{"label":"名称"}},[_c('el-input',{attrs:{"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"父级分类","required":""}},[_c('el-radio-group',{staticClass:"m-r-16",model:{value:(_vm.hasPid),callback:function ($$v) {_vm.hasPid=$$v},expression:"hasPid"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("无父级分类")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("有父级分类")])],1),_c('el-alert',{staticClass:"ls-tips",attrs:{"title":"选择无上级分类，则表明此分类为一级分类；选择有上级分类，则表明此分类为选中分类的子分类","type":"warning","closable":false}})],1),(_vm.hasPid == 1)?_c('el-form-item',{attrs:{"label":"","required":""}},[_c('el-cascader',{attrs:{"options":_vm.type_list,"props":{
								checkStrictly: true,
								value: 'id',
								label: 'name',
								children: 'sons',
								emitPath: false
							},"clearable":""},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return (data.level <= 2)?[_c('span',[_vm._v(_vm._s(data.name))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.sons.length)+") ")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.form.pid),callback:function ($$v) {_vm.$set(_vm.form, "pid", $$v)},expression:"form.pid"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}}),_c('div',{staticClass:"muted xs"},[_vm._v("排序值必须为整数；数值越大，越靠前")])],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("确认")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }