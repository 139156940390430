
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiSdvideoGoodsTypeLists,apiSdvideoGoodsTypeDel } from "@/api/sdvideo";
import MaterialSelect from "@/components/material-select/index.vue";
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import SdvideoGoodsTypeEdit from '@/components/sdvideo/sdvideo-goods-type-edit.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,	
        MaterialSelect,
		SdvideoGoodsTypeEdit
    },
})
export default class Source extends Vue {

    // 分页
    pager: RequestPaging = new RequestPaging()

    // 搜索表单
    searchForm = {
        type:'',
    }

    /** S Methods **/

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiSdvideoGoodsTypeLists,
                params: {},
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除
    onSourceDel(row: any) {     
	   apiSdvideoGoodsTypeDel({
            id: row.id
        }).then(() => {
            this.getList()
        })
    }

    // 搜索
    onSearch() {
        this.pager.page = 1
        this.getList()
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
    }

    /** E Life Cycle **/

}
