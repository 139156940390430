
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import {
	apiSdvideoGoodsTypeAdd,
	apiSdvideoGoodsTypeDetail,
	apiSdvideoGoodsTypeEdit,
	apiSdvideoGoodsTypeLists,
} from '@/api/sdvideo'
@Component({
    components: {
        MaterialSelect
    }
})
export default class ImgCategoryEdit extends Vue {
	@Prop() id?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        formRef: any
    }
	
    form = {
		id: 0,
        name: '',
        pid: 0,
        sort: 0,
    }
	
    hasPid = 0
    type_list = []

    // 表单验证
    formRules = {
        name: [
            {
                required: true,
                message: '请填写名称',
                trigger: ['blur', 'change']
            }
        ]
    }
    /** E Data **/

    // 详情
    getDetail() {
        apiSdvideoGoodsTypeDetail({
            id: this.form.id
        })
            .then((res: any) => {
				this.form.name = res.name
				this.form.pid = res.pid
				this.form.sort = res.sort
				if (res.pid) {
					this.hasPid = 1
				}				
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }
	
	// type
    getTypeList() {
        apiSdvideoGoodsTypeLists({ page_type: 1 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
            this.type_list = res?.lists
        })
    }	

    // 新增
    onSourceAdd() {
		if (!this.hasPid) {
			this.form.pid = 0
		}	
        apiSdvideoGoodsTypeAdd(this.form)
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑
    onSourceEdit() {
		if (!this.hasPid) {
			this.form.pid = 0
		}	
        apiSdvideoGoodsTypeEdit({
            ...this.form,
            id: this.id
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.form.id > 0) {
                this.onSourceEdit()
            } else {
                this.onSourceAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true
		this.getTypeList()
        if (this.id) {
			this.form.id = this.id
            this.getDetail()
        }		
    }

    // 关闭弹窗
    close() {
        this.visible = false
    }
    /** E Methods **/
}
